import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        this.element.addEventListener('input', (e) => this._onChange(e));
    }

    disconnect() {
        this.element.removeEventListener('input', (e) => this._onChange(e));
    }

    _onChange(event) {
        this.element.getElementsByClassName('current-step')[0].textContent = event.target.value;
    }
}
