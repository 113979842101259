import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        uri: String,
        params: Object,
        successMessage: String,
        errorMessage: String,
        method: String,
        confirm: Boolean,
    };

    async post() {
        const successMessage = this['successMessageValue'] || 'Success';
        const errorMessage = this['errorMessageValue'] || 'Something went wrong';

        if (this['confirmValue'] && !confirm('Are you sure?')) {
            return;
        }

        await fetch(this['uriValue'], {
            method: this['methodValue'] || 'POST',
            body: JSON.stringify(this['paramsValue']),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            credentials: 'same-origin',
        })
            .then(async (response) => {
                if (response.status > 299) {
                    const data = response.status > 400 ? await response.json() : null;

                    return window.showErrorMessage(data?.message?.length ? data.message : errorMessage);
                }

                return window.showSuccessMessage(successMessage);
            })
            .catch(() => {
                return window.showErrorMessage(errorMessage);
            });
    }
}
