import {Controller} from '@hotwired/stimulus';
import {Modal} from 'flowbite';

export default class extends Controller {
    static values = {
        id: String,
        modalId: String,
    };

    initialize() {
        const modalEl = document.getElementById(this['modalIdValue']);

        if (!modalEl) {
            throw `Modal element not found with id ${this['modalIdValue']}`;
        }

        this.modal = new Modal(
            modalEl,
            {
                backdrop: 'dynamic',
                backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
                closable: true,
            },
            {
                id: this['idValue'],
                override: true,
            }
        );
    }

    async show() {
        if (!this.modal) {
            throw 'Modal component is not initialized yet';
        }

        this.modal.show();
    }

    async hide() {
        if (!this.modal) {
            throw 'Modal component is not initialized yet';
        }

        this.modal.hide();
    }
}
