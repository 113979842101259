// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

// start the Stimulus application
import './bootstrap';

import 'flowbite';
import 'flowbite/dist/flowbite.turbo.js';
import 'flowbite/dist/datepicker.min';

import './js/sidebar';
import './js/theme-toggle';
import {shouldPerformTransition, performTransition} from 'turbo-view-transitions';

import './styles/tom-select.css';

/* global FlowbiteInstances:readonly */

window.showErrorMessage = function (message, duration = 8000) {
    if (message.trim() !== '') {
        window.dispatchEvent(
            new CustomEvent('showErrorMessage', {
                detail: {
                    message: message,
                    duration: duration,
                },
            })
        );
    }
};

window.showSuccessMessage = function (message, duration = 8000) {
    if (message.trim() !== '') {
        window.dispatchEvent(
            new CustomEvent('showSuccessMessage', {
                detail: {
                    message: message,
                    duration: duration,
                },
            })
        );
    }
};

let skipNextRenderTransition = false;

document.addEventListener('turbo:before-render', (event) => {
    if (shouldPerformTransition() && !skipNextRenderTransition) {
        event.preventDefault();
        performTransition(document.body, event.detail.newBody, async () => {
            await event.detail.resume();
        });
    }
});

document.addEventListener('turbo:load', () => {
    // View Transitions don't play nicely with Turbo cache
    if (shouldPerformTransition()) Turbo.cache.exemptPageFromCache();
});

document.addEventListener('turbo:before-frame-render', (event) => {
    if (shouldPerformTransition() && !event.target.hasAttribute('data-skip-transition')) {
        event.preventDefault();
        performTransition(event.target, event.detail.newFrame, async () => {
            await event.detail.resume();
        });

        // workaround for data-turbo-action="advance", which triggers
        // turbo:before-render (and we want THAT to not try to transition)
        skipNextRenderTransition = true;
        setTimeout(() => {
            skipNextRenderTransition = false;
        }, 100);
    }
});

document.querySelectorAll('[datepicker="true"]').forEach((item) => {
    item.addEventListener('changeDate', (e) => {
        e.target.dispatchEvent(new Event('change', {bubbles: true}));
    });
});

const $clipboardElements = document.getElementsByClassName('copy-to-clipboard');

for (let i = 0; i < $clipboardElements.length; i++) {
    const $el = $clipboardElements[i];

    const clipboard = FlowbiteInstances.getInstance('CopyClipboard', $el.id);
    const tooltip = FlowbiteInstances.getInstance('Tooltip', 'tooltip-copy-' + $el.id);
    const $defaultIcon = document.getElementById('default-icon-' + $el.id);
    const $successIcon = document.getElementById('success-icon-' + $el.id);
    const $defaultTooltipMessage = document.getElementById('default-tooltip-message-' + $el.id);
    const $successTooltipMessage = document.getElementById('success-tooltip-message-' + $el.id);

    clipboard.updateOnCopyCallback(() => {
        $defaultIcon.classList.add('hidden');
        $successIcon.classList.remove('hidden');
        $defaultTooltipMessage.classList.add('hidden');
        $successTooltipMessage.classList.remove('hidden');
        tooltip.show();

        // reset to default state
        setTimeout(() => {
            $defaultIcon.classList.remove('hidden');
            $successIcon.classList.add('hidden');
            $defaultTooltipMessage.classList.remove('hidden');
            $successTooltipMessage.classList.add('hidden');
            tooltip.hide();
        }, 2000);
    });
}
